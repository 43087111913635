import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"
import { MIN_MOBILE_MEDIA_QUERY } from "typography-breakpoint-constants";

Wordpress2016.overrideThemeStyles = ({ adjustFontSizeTo, rhythm }) => {
  return {
    [MIN_MOBILE_MEDIA_QUERY]: {
      h1: {
        ...adjustFontSizeTo("3rem"),
      },
    },
    h1: {
      ...adjustFontSizeTo("2rem"),
      fontFamily: ["Kanit", "sans-serif"].join(","),
    },
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
    a: {
      color: "#d5586a",
    },
    "ul,ol": {
      marginLeft: rhythm(1),
    },
  }
}

delete Wordpress2016.googleFonts
Wordpress2016.headerFontFamily = ["Arial", "sans-serif"];
Wordpress2016.bodyFontFamily = ["Georgia", "serif"];

const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
